import React from 'react';
import { Alert } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

interface NameInfoProps {
    name?: string | null;
}

function NameInfo({ name }: NameInfoProps) {
    return name ?
        <Alert variant="primary" className="mt-3">
            <FormattedMessage id="control.name" defaultMessage="You play as {name}."
                values={{ name: <strong>{name}</strong> }} />
        </Alert> :
        null;
}

export default NameInfo;
