import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import IntlWrapper from './components/IntlWrapper/IntlWrapper';
import KwamisInfoPage from './components/Pages/KwamisInfoPage';
import MainPage from './components/Pages/MainPage';
import NotFoundPage from './components/Pages/NotFoundPage';
import QuizPage from './components/Pages/QuizPage';
import './index.css';
import { preLoadImages } from './helpers/quiz';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/:locale/kwamis" element={<IntlWrapper><KwamisInfoPage /></IntlWrapper>} />
                <Route path="/:locale" element={<IntlWrapper><QuizPage /></IntlWrapper>} />
                <Route path="/:locale/*" element={<IntlWrapper><NotFoundPage /></IntlWrapper>} />
                <Route path="/" element={<MainPage />} />
                <Route path="*" element={<IntlWrapper><NotFoundPage /></IntlWrapper>} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);
preLoadImages();
