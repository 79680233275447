import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { Kwami } from '../../data/kwamis';
import { getTypeForMode, OPTIONS_NUMBER } from '../../data/quiz';
import { translateKwami } from '../../helpers/intl';
import { getOptions } from '../../helpers/quiz';
import { Mode, ModeType } from '../../types/mode';
import { Question } from '../../types/quiz';
import CharacterImage from '../MiraculousImage/CharacterImage';
import KwamiImage from '../MiraculousImage/KwamiImage';

interface QuizAnswersProps {
    addAnswer: (option: Kwami) => void;
    mode: Mode;
    question: Question;
    renderName?: boolean;
}

function QuizAnswers({ addAnswer, mode, question, renderName }: QuizAnswersProps) {
    const { locale } = useIntl();
    const options = getOptions(OPTIONS_NUMBER, question);

    switch (getTypeForMode(mode)) {
        case ModeType.GUESS_IMAGE:
            return <div className="text-center">
                <div className="mt-3">
                    <KwamiImage kwami={question} renderName={renderName} />
                </div>
                <ButtonGroup id="mode" className="answers mt-3" vertical>
                    {options.map((option, i) => <Button key={`${question.id}-${i}`} onClick={() => {
                        addAnswer(option);
                    }} variant="outline-primary" size="lg">
                        {translateKwami(option, mode, locale)}
                    </Button>)}
                </ButtonGroup>
            </div>;

        case ModeType.GUESS_IMAGE_BY_IMAGE:
            return <div className="text-center">
                <div className="mt-3">
                    {mode === Mode.GUESS_BY_CHARACTER ?
                        <CharacterImage kwami={question} /> :
                        <KwamiImage kwami={question} renderName={renderName} />}
                </div>
                <div className="answers mt-3">
                    {options.map((option, i) => mode === Mode.GUESS_CHARACTER ?
                        <CharacterImage kwami={option} onClick={() => {
                            addAnswer(option);
                        }} key={i} /> :
                        <KwamiImage kwami={option} renderName={renderName} onClick={() => {
                            addAnswer(option);
                        }} key={i} />)}
                </div>
            </div>;

        case ModeType.GUESS_BY_IMAGE:
        default:
            return <div className="text-center">
                <div className="answers mt-3">
                    {options.map((option, i) => <KwamiImage kwami={option} renderName={renderName} onClick={() => {
                        addAnswer(option);
                    }} key={i} />)}
                </div>
            </div>;
    }
}

export default QuizAnswers;
