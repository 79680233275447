import { I10nString, Kwami } from '../data/kwamis';
import messagesEn from '../translations/messages-en.json';
import messagesPl from '../translations/messages-pl.json';
import { Mode } from '../types/mode';

export const TRANSLATIONS: { [locale: string]: { messages: { [key: string]: string }; name: string } } = {
    en: {
        messages: messagesEn,
        name: 'English',
    },
    pl: {
        messages: messagesPl,
        name: 'Polski',
    },
};
export const DEFAULT_LOCALE = 'en';

function getTranslation(text: I10nString, locale?: string): { name: string, plural: boolean } {
    return locale && locale in text ? text[locale] : text[DEFAULT_LOCALE];
}

function getTranslationNames(text: I10nString) {
    return Object.values(text).map(value => value.name);
}

function getI10nStringByMode(kwami: Kwami, mode: Mode): I10nString | null {
    switch (mode) {
        case Mode.GUESS_BY_CHARACTER:
        case Mode.GUESS_CHARACTER:
            return kwami.character.name;

        case Mode.GUESS_IMAGE_BY_MIRACULOUS:
        case Mode.GUESS_MIRACULOUS:
            return kwami.miraculous;

        case Mode.GUESS_IMAGE_BY_NAME:
        case Mode.GUESS_NAME:
        case Mode.TYPE_NAME:
            return kwami.name;

        case Mode.GUESS_IMAGE_BY_POWER:
        case Mode.GUESS_POWER:
            return kwami.power;

        case Mode.GUESS_IMAGE_BY_TRANSFORMATION:
        case Mode.GUESS_TRANSFORMATION:
            return kwami.transformation;

        default:
            return null;
    }
}

export function translateString(text: I10nString, locale?: string): string {
    return getTranslation(text, locale).name;
}

export function isStringPlural(text: I10nString, locale?: string): boolean {
    return getTranslation(text, locale).plural;
}

export function translateKwami(kwami: Kwami, mode: Mode, locale?: string): string {
    const text = getI10nStringByMode(kwami, mode);
    return text ? getTranslation(text, locale).name : '';
}

export function getKwamiTranslationNames(kwami: Kwami, mode: Mode): string[] {
    const text = getI10nStringByMode(kwami, mode);
    return text ? getTranslationNames(text) : [];
}

export function localeExists(locale: string) {
    return Object.keys(TRANSLATIONS).includes(locale);
}

export function getFlagClassName(locale: string): string {
    return `fi fi-${locale === 'en' ? 'gb' : locale}`;
}
