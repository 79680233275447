import { Mode, ModeType } from '../types/mode';
import { KWAMIS } from './kwamis';

export const QUESTIONS_NUMBER = 5;
export const OPTIONS_NUMBER = 3;
export const QUESTIONS = KWAMIS;

export function getTypeForMode(mode: Mode): ModeType | null {
    switch (mode) {
        case Mode.GUESS_IMAGE_BY_MIRACULOUS:
        case Mode.GUESS_IMAGE_BY_NAME:
        case Mode.GUESS_IMAGE_BY_POWER:
        case Mode.GUESS_IMAGE_BY_TRANSFORMATION:
            return ModeType.GUESS_BY_IMAGE;
        case Mode.GUESS_MIRACULOUS:
        case Mode.GUESS_NAME:
        case Mode.GUESS_POWER:
        case Mode.GUESS_TRANSFORMATION:
            return ModeType.GUESS_IMAGE;
        case Mode.GUESS_BY_CHARACTER:
        case Mode.GUESS_CHARACTER:
            return ModeType.GUESS_IMAGE_BY_IMAGE;
        default:
            return null;
    }
}
