import { Kwami } from '../../data/kwamis';
import MiraculousImage from './MiraculousImage';

interface KwamiImageProps {
    kwami: Kwami;
    onClick?: () => void;
    renderName?: boolean;
}

function KwamiImage({ kwami, onClick, renderName }: KwamiImageProps) {
    return <MiraculousImage {...{
        backgroundColor: kwami.color,
        fontColor: kwami.fontColor,
        image: kwami.image,
        name: renderName === false ? undefined : kwami.name,
        onClick,
    }} />;
}

export default KwamiImage;
