import React, { useCallback, useState } from 'react';
import { Badge, Button, ButtonGroup, Container, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BookFill, PenFill, StarFill } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Mode, Modes, Skill } from '../../types/mode';
import Header from '../Header/Header';
import NameInfo from '../NameInfo/NameInfo';
import Quiz from '../Quiz/Quiz';

function QuizPage() {
    const { formatMessage } = useIntl();
    const [nameError, setNameError] = useState<boolean>(false);
    const [nameValue, setNameValue] = useState<string>('');
    const [name, setName] = useState<string | null>(null);
    const [mode, setMode] = useState<Mode | null>(null);
    const resetMode = useCallback(() => {
        setMode(null);
    }, [setMode]);

    if (!name || !mode) {
        return <>
            <Header resetMode={resetMode} />
            <Container className="pb-3">
                <NameInfo name={name} />
                <Form.Label htmlFor="name" className="mt-3">
                    <FormattedMessage id="control.name-label" defaultMessage="Hi! What's your name?" />
                </Form.Label>
                <Form.Control className="mt-3" type="text" id="name" value={nameValue} onChange={(event: any) => {
                    setNameValue(event.target.value);
                    setNameError(false);
                }} placeholder={formatMessage({
                    defaultMessage: 'Type your name...', id: 'control.type-your-name',
                })} isInvalid={nameError} autoFocus />
                {nameError ?
                    <Form.Control.Feedback type="invalid">
                        <FormattedMessage id="control.no-name-error" defaultMessage="Type your name first, please." />
                    </Form.Control.Feedback> :
                    null}
                <Form.Label htmlFor="mode" className="mt-3">
                    <FormattedMessage id="control.select-mode" defaultMessage="Select quiz mode:" />
                </Form.Label>
                <div className="text-center mt-3">
                    <ButtonGroup id="mode" vertical>
                        {Object.entries(Modes).map(
                            ([id, { defaultMessage, difficultyLevel, skills }]) => <Button key={id} onClick={() => {
                                if (nameValue) {
                                    setName(nameValue);
                                    setMode(id as Mode);
                                    setNameError(false);
                                } else {
                                    setNameError(true);
                                }
                            }} variant="outline-primary" size="lg">
                                <FormattedMessage defaultMessage={defaultMessage} id={`mode.${id}`} />{' '}
                                {difficultyLevel > 0 ?
                                    <span style={{ fontSize: '54%' }}>{' '}
                                        <OverlayTrigger placement="right" overlay={props => <Tooltip {...props}>
                                            <FormattedMessage defaultMessage="difficulty level"
                                                id="title.difficulty-level" />
                                        </Tooltip>} delay={{ hide: 200, show: 200 }}>
                                            <Badge pill bg="info">
                                                {[...Array(difficultyLevel)].map((_, i) => <StarFill key={i} />)}
                                            </Badge>
                                        </OverlayTrigger>
                                    </span> :
                                    null}
                                {skills.includes(Skill.READ) ?
                                    <span style={{ fontSize: '54%' }}>{' '}
                                        <OverlayTrigger placement="right" overlay={props => <Tooltip {...props}>
                                            <FormattedMessage defaultMessage="reading skill needed"
                                                id="title.skill-needed.reading" />
                                        </Tooltip>} delay={{ hide: 200, show: 200 }}>
                                            <Badge pill bg="warning"><BookFill /></Badge>
                                        </OverlayTrigger>
                                    </span> :
                                    null}
                                {skills.includes(Skill.WRITE) ?
                                    <span style={{ fontSize: '54%' }}>{' '}
                                        <OverlayTrigger placement="right" overlay={props => <Tooltip {...props}>
                                            <FormattedMessage defaultMessage="writing skill needed"
                                                id="title.skill-needed.writing" />
                                        </Tooltip>} delay={{ hide: 200, show: 200 }}>
                                            <Badge pill bg="danger"><PenFill /></Badge>
                                        </OverlayTrigger>
                                    </span> :
                                    null}
                            </Button>,
                        )}
                    </ButtonGroup>
                </div>
            </Container>
        </>
    }

    return <>
        <Header resetMode={resetMode} />
        <Container className="pb-3">
            <NameInfo name={name} />
            <Quiz mode={mode} />
        </Container>
    </>;
}

export default QuizPage;
