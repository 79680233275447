import { PropsWithChildren } from 'react';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';
import { DEFAULT_LOCALE, localeExists, TRANSLATIONS } from '../../helpers/intl';
import NotFoundPage from '../Pages/NotFoundPage';

function IntlWrapper({ children }: PropsWithChildren) {
    const { locale } = useParams() as { locale: string };
    const localeOrDefault = localeExists(locale) ? locale : DEFAULT_LOCALE;
    return <IntlProvider locale={localeOrDefault} messages={TRANSLATIONS[localeOrDefault].messages}
        defaultLocale={DEFAULT_LOCALE}>
        {localeExists(locale) ? children : <NotFoundPage />}
    </IntlProvider>;
}

export default IntlWrapper;
