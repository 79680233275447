import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Header from '../Header/Header';

function NotFoundPage () {
    return <>
        <Header />
        <Container className="pb-3">
            <Alert variant="danger" className="mt-3">
                <FormattedMessage id="error.page-not-found" defaultMessage="Page not found" />
            </Alert>
        </Container>
    </>;
}

export default NotFoundPage;
