import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { isStringPlural, translateString } from '../../helpers/intl';
import { Mode } from '../../types/mode';
import { Question } from '../../types/quiz';

interface QuizQuestionProps {
    mode: Mode;
    question: Question;
}

function QuizQuestion({ mode, question }: QuizQuestionProps) {
    const { locale } = useIntl();
    switch (mode) {
        case Mode.GUESS_BY_CHARACTER:
            return <FormattedMessage id="question.mode.guess-by-character"
                defaultMessage="Whose character is it?" />;
        case Mode.GUESS_IMAGE_BY_MIRACULOUS:
            if (isStringPlural(question.miraculous, locale)) {
                return <FormattedMessage id="question.mode.guess-image-by-miraculous.plural"
                    defaultMessage="Whose miraculous are {miraculous}?"
                    values={{ miraculous: <strong>{translateString(question.miraculous, locale)}</strong> }} />;
            }
            return <FormattedMessage id="question.mode.guess-image-by-miraculous.singular"
                defaultMessage="Whose miraculous is {miraculous}?"
                values={{ miraculous: <strong>{translateString(question.miraculous, locale)}</strong> }} />;
        case Mode.GUESS_IMAGE_BY_NAME:
            return <FormattedMessage id="question.mode.guess-image-by-name"
                defaultMessage="How {name} looks like?"
                values={{ name: <strong>{translateString(question.name, locale)}</strong> }} />;
        case Mode.GUESS_IMAGE_BY_POWER:
            if (isStringPlural(question.power, locale)) {
                return <FormattedMessage id="question.mode.guess-image-by-power.plural"
                    defaultMessage="Whose power are {power}?"
                    values={{ power: <strong>{translateString(question.power, locale)}</strong> }} />;
            }
            return <FormattedMessage id="question.mode.guess-image-by-power.singular"
                defaultMessage="Whose power is {power}?"
                values={{ power: <strong>{translateString(question.power, locale)}</strong> }} />;
        case Mode.GUESS_IMAGE_BY_TRANSFORMATION:
            if (isStringPlural(question.transformation, locale)) {
                return <FormattedMessage id="question.mode.guess-image-by-transformation.plural"
                    defaultMessage="Whose transformation are {transformation}?"
                    values={{ transformation: <strong>{translateString(question.transformation, locale)}</strong> }} />
            }
            return <FormattedMessage id="question.mode.guess-image-by-transformation.singular"
                defaultMessage="Whose transformation is {transformation}?"
                values={{ transformation: <strong>{translateString(question.transformation, locale)}</strong> }} />
        case Mode.GUESS_CHARACTER:
            return <FormattedMessage id="question.mode.guess-character"
                defaultMessage="What's the character for this kwami?" />
        case Mode.GUESS_MIRACULOUS:
            return <FormattedMessage id="question.mode.guess-miraculous"
                defaultMessage="What's the miraculous of this kwami?" />
        case Mode.GUESS_NAME:
            return <FormattedMessage id="question.mode.guess-name" defaultMessage="What's the name of this kwami?" />;
        case Mode.GUESS_POWER:
            return <FormattedMessage id="question.mode.guess-power" defaultMessage="What's the power of this kwami?" />;
        case Mode.GUESS_TRANSFORMATION:
            return <FormattedMessage id="question.mode.guess-transformation"
                defaultMessage="What's the transformation for this kwami?" />;
        case Mode.TYPE_NAME:
            return <FormattedMessage id="question.mode.type-name" defaultMessage="What's the name of this kwami?" />;
        default:
            return null;
    }
}

export default QuizQuestion;
