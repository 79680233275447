import { Kwami } from '../../data/kwamis';
import MiraculousImage from './MiraculousImage';

interface CharacterImageProps {
    kwami: Kwami;
    onClick?: () => void;
}

function CharacterImage({ kwami, onClick }: CharacterImageProps) {
    return <MiraculousImage {...{
        image: kwami.character.image,
        name: kwami.character.name,
        onClick,
    }} />;
}

export default CharacterImage;
