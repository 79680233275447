import { DEFAULT_LOCALE, TRANSLATIONS } from '../helpers/intl';

export const IMAGES_BASE_URL = 'https://static.wikia.nocookie.net/lady-bug/images';

export interface I10nString {
    [locale: string]: { name: string, plural: boolean };
}
export interface Kwami {
    color: string;
    id: number;
    fontColor?: string;
    image: string;
    miraculous: I10nString;
    name: I10nString;
    power: I10nString;
    character: {
        name: I10nString,
        image: string,
    },
    transformation: I10nString;
}

function createI10nString(
    text: string | { [locale: string]: string | { name: string, plural?: boolean } },
): I10nString {
    if (typeof text === 'string') {
        return { [DEFAULT_LOCALE]: { name: text, plural: false } };
    }
    if (!(DEFAULT_LOCALE in text)) {
        text[DEFAULT_LOCALE] = Object.values(text)[0] || '';
    }
    return Object.entries(text).reduce((list, [locale, value]) => {
        if (!Object.keys(TRANSLATIONS).includes(locale)) {
            return list;
        }
        return {
            ...list,
            [locale]: typeof value === 'string' ? { name: value, plural: false } : { plural: false, ...value },
        };
    }, {});
}

// Sources:
// - https://miraculousladybug.fandom.com/wiki/Kwamis
// - https://miraculousladybug.fandom.com/pl/wiki/Kwami
export const KWAMIS: Kwami[] = [
    {
        character: {
            name: { en: 'Ladybug', es: 'Ladybug', pl: 'Biedronka' },
            image: '/5/59/Ladybug_Square.png',
        },
        color: '#98000a',
        image: '/d/d2/Tikki_Square.png',
        miraculous: {
            en: { name: 'earrings', plural: true },
            es: { name: 'aretes', plural: true },
            pl: { name: 'kolczyki', plural: true },
        },
        name: 'Tikki',
        power: { en: 'creation', es: 'creación', pl: 'tworzenie' },
        transformation: { en: 'ladybug', es: 'mariquita', pl: 'biedronka' },
    },
    {
        character: {
            name: { en: 'Hawk Moth', es: 'Hawk Moth', pl: 'Władca Ciem' },
            image: '/9/94/Hawk_Moth_Square.png',
        },
        color: '#800080',
        fontColor: '#fff',
        image: '/5/57/Nooroo_Square.png',
        miraculous: { en: 'locket brooch', es: 'broche guardapelo', pl: 'broszka medalionowa' },
        name: 'Nooroo',
        power: { en: 'transmission', es: 'transmisión', pl: 'przemienianie' },
        transformation: { en: 'butterfly', es: 'mariposa', pl: 'motyl' },
    },
    {
        character: {
            name: { en: 'Rena Rouge', es: 'Rena Rouge', pl: 'Ruda Kitka' },
            image: '/2/22/Rena_Rouge_Square.png',
        },
        color: '#f78e21',
        image: '/6/6a/Trixx_Square.png',
        miraculous: { en: 'necklace', es: 'collar', pl: 'naszyjnik' },
        name: 'Trixx',
        power: { en: 'illusion', es: 'ilusión', pl: 'iluzja' },
        transformation: { en: 'fox', es: 'zorro', pl: 'lis' },
    },
    {
        character: {
            name: { en: 'Mayura', es: 'Mayura', pl: 'Mayura' },
            image: '/8/86/Mayura_Square.png',
        },
        color: '#000080',
        fontColor: '#fff',
        image: '/4/4e/Duusu_Square.png',
        miraculous: { en: 'brooch', es: 'broche', pl: 'broszka' },
        name: 'Duusu',
        power: { en: 'emotion', es: 'emoción', pl: 'emocja' },
        transformation: { en: 'peacock', es: 'pavo real', pl: 'paw' },
    },
    {
        character: {
            name: { en: 'Minotaurox', es: 'Minotauro', pl: 'Minotaurox' },
            image: '/d/d7/Minotaurox_Square.png',
        },
        color: '#19194d',
        fontColor: '#fff',
        image: '/c/c8/Stompp_Square.png',
        miraculous: { en: 'nose ring', es: 'aro nasal', pl: 'kolczyk w nosie' },
        name: 'Stompp',
        power: { en: 'determination', es: 'determinación', pl: 'determinacja' },
        transformation: { en: 'ox', es: 'buey', pl: 'wół' },
    },
    {
        character: {
            name: { en: 'Bunnyx', es: 'Bunnyx', pl: 'Królix' },
            image: '/c/cc/Bunnyx_Square.png',
        },
        color: '#5cbadf',
        image: '/d/d6/Fluff_Square.png',
        miraculous: { en: 'pocket watch', es: 'reloj de bolsillo', pl: 'zegarek kieszonkowy' },
        name: { en: 'Fluff', es: 'Fluff', pl: 'Puszek' },
        power: { en: 'evolution', es: 'evolución', pl: 'ewolucja' },
        transformation: { en: 'rabbit', es: 'conejo', pl: 'królik' },
    },
    {
        character: {
            name: { en: 'Viperion', es: 'Viperion', pl: 'Serpention' },
            image: '/f/fd/Viperion_Square.png',
        },
        color: '#096',
        image: '/6/69/Sass_Square.png',
        miraculous: { en: 'ouroboros bracelet', es: 'brazalete', pl: 'bransoletka uroborosa' },
        name: 'Sass',
        power: { en: 'intuition', es: 'intuición', pl: 'intuicja' },
        transformation: { en: 'snake', es: 'serpiente', pl: 'wąż' },
    },
    {
        character: {
            name: { en: 'Caprikid', es: 'Caprichico', pl: 'Capriziom' },
            image: '/a/a2/Caprikid_Square.png',
        },
        color: '#666',
        fontColor: '#fff',
        image: '/a/a3/Ziggy_Square.png',
        miraculous: {
            en: { name: 'hair clips', plural: true },
            es: { name: 'pinzas de cabello', plural: true },
            pl: { name: 'spinki do włosów', plural: true },
        },
        name: 'Ziggy',
        power: { en: 'passion', es: 'pasión', pl: 'pasja' },
        transformation: { en: 'goat', es: 'cabro', pl: 'koza' },
    },
    {
        character: {
            name: { en: 'Rooster Bold', es: 'Gallo Féroz', pl: 'Kogut Śmiały' },
            image: '/6/61/Rooster_Bold_Square.png',
        },
        color: '#c30',
        image: '/9/92/Orikko_Square.png',
        miraculous: { en: 'thumb ring', es: 'anillo de pulgar', pl: 'pierścień na kciuk' },
        name: 'Orikko',
        power: { en: 'pretension', es: 'pretensión', pl: 'roszczenie' },
        transformation: { en: 'rooster', es: 'gallo', pl: 'kogut' },
    },
    {
        character: {
            name: { en: 'Pigella', es: 'Pigella', pl: 'Pigella' },
            image: '/3/3e/Pigella_Square.png',
        },
        color: '#ffd0e7',
        image: '/5/57/Daizzi_Square.png',
        miraculous: { en: 'pearl anklet', es: 'tobillera de perlas', pl: 'perłowa bransoletka na kostkę' },
        name: 'Daizzi',
        power: { en: 'jubilation', es: 'júbilo', pl: 'radość' },
        transformation: { en: 'pig', es: 'cerda', pl: 'świnia' },
    },
    {
        character: {
            name: { en: 'Cat Noir', es: 'Cat Noir', pl: 'Czarny Kot' },
            image: '/7/7b/Cat_Noir_Square.png',
        },
        color: '#000',
        fontColor: '#fff',
        image: '/2/2b/Plagg_Square.png',
        miraculous: { en: 'ring', es: 'anillo', pl: 'pierścień' },
        name: 'Plagg',
        power: { en: 'destruction', es: 'destrucción', pl: 'destrukcja' },
        transformation: { en: 'cat', es: 'gato', pl: 'kot' },
    },
    {
        character: {
            name: { en: 'Carapace', es: 'Caparazón', pl: 'Pancernik' },
            image: '/6/67/Carapace_Square.png',
        },
        color: '#51c561',
        image: '/4/4b/Wayzz_Square.png',
        miraculous: { en: 'bracelet', es: 'pulsera', pl: 'bransoletka' },
        name: 'Wayzz',
        power: { en: 'protection', es: 'protección', pl: 'ochrona' },
        transformation: { en: 'turtle', es: 'tortuga', pl: 'żółw' },
    },
    {
        character: {
            name: { en: 'Vesperia', es: 'Vesperia', pl: 'Vesperia' },
            image: '/1/1c/Vesperia_Square.png',
        },
        color: '#daa520',
        image: '/2/27/Pollen_Square.png',
        miraculous: { en: 'hair comb', es: 'peineta', pl: 'grzebień' },
        name: 'Pollen',
        power: { en: 'action', es: 'acción', pl: 'akcja' },
        transformation: { en: 'bee', es: 'abeja', pl: 'pszczoła' },
    },
    {
        character: {
            name: { en: 'Polymouse', es: 'Polirratón', pl: 'Multimysz' },
            image: '/7/7f/Polymouse_Square.png',
        },
        color: '#666',
        fontColor: '#fff',
        image: '/8/8d/Mullo_Square.png',
        miraculous: { en: 'pendant necklace', es: 'medallón', pl: 'naszyjnik z wisiorkiem' },
        name: 'Mullo',
        power: { en: 'multiplication', es: 'multiplicación', pl: 'rozmnażanie' },
        transformation: { en: 'mouse', es: 'ratón', pl: 'mysz' },
    },
    {
        character: {
            name: { en: 'Purple Tigress', es: 'Tigresa Púrpura', pl: 'Fioletowa Tygrysica' },
            image: '/2/22/Purple_Tigress_Square.png',
        },
        color: '#800040',
        fontColor: '#fff',
        image: '/4/4d/Roaar_Square.png',
        miraculous: { en: 'panjas bracelet', es: 'pulsera panjas', pl: 'bransoletka panja' },
        name: 'Roaar',
        power: { en: 'elation', es: 'exaltación', pl: 'egzaltacja' },
        transformation: { en: 'tiger', es: 'tigre', pl: 'tygrys' },
    },
    {
        character: {
            name: { en: 'Ryuko', es: 'Ryuko', pl: 'Ryuko' },
            image: '/4/47/Ryuko_Square.png',
        },
        color: '#c00',
        image: '/4/45/Longg_Square.png',
        miraculous: { en: 'beaded choker', es: 'gargantilla', pl: 'naszyjnik z koralików' },
        name: 'Longg',
        power: { en: 'perfection', es: 'perfección', pl: 'doskonałość' },
        transformation: { en: 'dragon', es: 'continuar', pl: 'smok' },
    },
    {
        character: {
            name: { en: 'Pegasus', es: 'Pegaso', pl: 'Pegaz' },
            image: '/b/b7/Pegasus_Square.png',
        },
        color: '#765',
        fontColor: '#fff',
        image: '/4/42/Kaalki_Square.png',
        miraculous: {
            en: { name: 'glasses', plural: true },
            es: { name: 'lentes', plural: true },
            pl: { name: 'okulary', plural: true },
        },
        name: 'Kaalki',
        power: { en: 'migration', es: 'migración', pl: 'migracja' },
        transformation: { en: 'horse', es: 'caballo', pl: 'koń' },
    },
    {
        character: {
            name: { en: 'King Monkey', es: 'Rey Mono', pl: 'Król Małp' },
            image: '/9/96/King_Monkey_Square.png',
        },
        color: '#b97',
        image: '/d/d6/Xuppu_Square.png',
        miraculous: { en: 'circlet', es: 'diadema', pl: 'diadem' },
        name: 'Xuppu',
        power: { en: 'derision', es: 'irrisión', pl: 'szyderstwo' },
        transformation: { en: 'monkey', es: 'mono', pl: 'małpa' },
    },
    {
        character: {
            name: { en: 'Miss Hound', es: 'Sabuesa', pl: 'Gończa' },
            image: '/a/ae/Miss_Hound_Square.png',
        },
        color: '#c63',
        image: '/e/e5/Barkk_Square.png',
        miraculous: { en: 'collar', es: 'collarín', pl: 'obroża' },
        name: 'Barkk',
        power: { en: 'adoration', es: 'adoración', pl: 'uwielbienie' },
        transformation: { en: 'dog', es: 'sabuesa', pl: 'pies' },
    },
    {
        character: {
            name: { en: 'Eagle', es: 'Águila', pl: 'Orlica' },
            image: '/1/19/Eagle_Square.png',
        },
        color: '#ff2400',
        image: '/9/9f/Liiri_Square.png',
        miraculous: { en: 'talon pendant', es: 'colgante de garra', pl: 'naszyjnik ze szponem' },
        name: 'Liiri',
        power: { en: 'freedom', es: 'libertad', pl: 'wolność' },
        transformation: { en: 'eagle', es: 'águila', pl: 'orzeł' },
    },
].map((kwami, i) => ({
    ...kwami,
    character: {
        ...kwami.character,
        name: createI10nString(kwami.character.name),
    },
    id: i + 1,
    miraculous: createI10nString(kwami.miraculous),
    name: createI10nString(kwami.name),
    power: createI10nString(kwami.power),
    transformation: createI10nString(kwami.transformation),
}));
