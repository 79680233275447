import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Kwami } from '../../data/kwamis';
import { QUESTIONS_NUMBER } from '../../data/quiz';
import { getKwamiTranslationNames, translateKwami } from '../../helpers/intl';
import { getQuestions } from '../../helpers/quiz';
import { Mode, Modes } from '../../types/mode';
import { Answer, Question } from '../../types/quiz';
import KwamiImage from '../MiraculousImage/KwamiImage';
import QuizAnswers from './QuizAnswers';
import QuizQuestion from './QuizQuestion';
import QuizResult from './QuizResult';

interface QuizProps {
    mode: Mode;
}

function Quiz({ mode }: QuizProps) {
    const { formatMessage, locale } = useIntl();
    const [questions, setQuestions] = useState<Question[]>(() => getQuestions(QUESTIONS_NUMBER));
    const [answers, setAnswers] = useState<Answer[]>([]);
    const [textAnswer, setTextAnswer] = useState<string>('');
    const addAnswer = useCallback((mode: Mode, proper: Kwami, given: Kwami) => {
        const result = translateKwami(proper, mode, locale) === translateKwami(given, mode, locale);
        setAnswers([...answers, { given, mode, proper, result }]);
    }, [answers, setAnswers, locale]);
    const addTextAnswer = useCallback((mode: Mode, proper: Kwami, given: string) => {
        const result = getKwamiTranslationNames(proper, mode).includes(given);
        setAnswers([...answers, { given, mode, proper, result }]);
    }, [answers, setAnswers]);
    useEffect(() => {
        setTextAnswer('');
    }, [answers]);

    const total = questions.length;
    if (answers.length === total) {
        const score = answers.filter(answer => answer.result).length;
        return <div>
            <Alert variant={score === 0 ? 'danger' : (score === total ? 'success' : 'warning')}>
                <p>
                    <FormattedMessage id="answers.score" defaultMessage="Your score: {score} / {total}"
                        values={{ score: <strong style={{ fontSize: '180%' }}>{score}</strong>, total }} />
                </p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button onClick={() => {
                        setQuestions(getQuestions(QUESTIONS_NUMBER));
                        setAnswers([]);
                    }}>
                        <ArrowClockwise />{' '}
                        <FormattedMessage id="button.again" defaultMessage="Try once again" />
                    </Button>
                </div>
            </Alert>
            <div>
                <p><FormattedMessage id="answers.title" defaultMessage="Answers:" /></p>
                {answers.map((answer, i) => <Alert key={i} variant={answer.result ? 'success' : 'danger'}>
                    <p>{i + 1}: <QuizQuestion question={answer.proper} mode={mode} /></p>
                    <QuizResult answer={answer} mode={mode} />
                </Alert>)}
            </div>
        </div>;
    }

    const question = questions[answers.length];
    if (mode === Mode.TYPE_NAME) {
        const send = () => {
            if (textAnswer) {
                addTextAnswer(mode, question, textAnswer);
            }
        };
        return <div>
            <p>{answers.length + 1}/{total}: <QuizQuestion mode={mode} question={question}/></p>
            <div className="text-center">
                <div className="mt-3">
                    <KwamiImage kwami={question} renderName={false}/>
                </div>
                <InputGroup className="mt-3">
                    <Form.Control key={question.id} value={textAnswer} maxLength={10} onChange={(event: any) => {
                        const value = `${event.target.value}`.toLowerCase();
                        setTextAnswer(value ? (value.charAt(0).toUpperCase() + value.slice(1)) : '');
                    }} placeholder={formatMessage({
                        defaultMessage: 'Type name...', id: 'control.type-name',
                    })} onKeyUp={(event: { key?: string }) => {
                        if (event.key === 'Enter') {
                            send();
                        }
                    }} autoFocus/>
                    <Button onClick={send}><FormattedMessage id="button.ok" defaultMessage="OK"/></Button>
                </InputGroup>
            </div>
        </div>;
    }
    if (mode in Modes) {
        return <div>
            <p>{answers.length + 1}/{total}: <QuizQuestion mode={mode} question={question} /></p>
            <QuizAnswers mode={mode} question={question} addAnswer={option => {
                addAnswer(mode, question, option);
            }} renderName={mode !== Mode.GUESS_IMAGE_BY_NAME && mode !== Mode.GUESS_NAME} />
        </div>;
    }
    return <Alert variant="danger" className="mt-3">
        <FormattedMessage id="control.invalid-mode" defaultMessage="Invalid quiz mode!" />
    </Alert>;
}

export default Quiz;
