import { sortBy } from 'lodash';
import React from 'react';
import { Card, Container, ListGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { IMAGES_BASE_URL, KWAMIS } from '../../data/kwamis';
import { translateString } from '../../helpers/intl';
import Header from '../Header/Header';
import './KwamisInfoPage.css';

function KwamisInfoPage() {
    const { locale } = useIntl();
    const kwamis = KWAMIS.map(kwami => ({ ...kwami, translatedName: translateString(kwami.name, locale) }));

    return <>
        <Header />
        <Container className="KwamisInfoPage pb-3">
            {sortBy(kwamis, ['name']).map(kwami => <div key={kwami.id} className="kwami mt-3">
                <Card>
                    <Card.Img variant="top" src={`${IMAGES_BASE_URL}${kwami.image}`} />
                    <Card.Body>
                        <Card.Title>{kwami.translatedName}</Card.Title>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                        <ListGroup.Item>
                            <FormattedMessage id="kwami-info.power" defaultMessage="Power: {power}"
                                values={{ power: translateString(kwami.power, locale) }} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FormattedMessage id="kwami-info.transformation"
                                defaultMessage="Transformation: {transformation}"
                                values={{ transformation: translateString(kwami.transformation, locale) }} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FormattedMessage id="kwami-info.miraculous" defaultMessage="Miraculous: {miraculous}"
                                values={{ miraculous: translateString(kwami.miraculous, locale) }} />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <FormattedMessage id="kwami-info.character" defaultMessage="Character: {character}"
                                values={{ character: translateString(kwami.character.name, locale) }} />
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            </div>)}
        </Container>
    </>;
}

export default KwamisInfoPage;
