import { Image } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { IMAGES_BASE_URL, I10nString } from '../../data/kwamis';
import { translateString } from '../../helpers/intl';
import './MiraculousImage.css';

interface MiraculousImageProps {
    backgroundColor?: string;
    fontColor?: string;
    image: string;
    name?: I10nString;
    onClick?: () => void;
}

function MiraculousImage({ backgroundColor, fontColor, image, name, onClick }: MiraculousImageProps) {
    const { locale } = useIntl();
    const style: { backgroundColor?: string; color?: string } = backgroundColor ? { backgroundColor } : {};
    if (fontColor) {
        style.color = fontColor;
    }
    const imgProps = {
        alt: name ? translateString(name, locale) : '',
        className: onClick ? 'clickable-img' : '',
        onClick,
        src: `${IMAGES_BASE_URL}${image}`,
        style,
        thumbnail: true,
    };
    return <div className="MiraculousImage">
        <Image {...imgProps} />
        {name ?
            <span style={style}>{translateString(name, locale)}</span> :
            null}
    </div>;
}

export default MiraculousImage;
