import { isString } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { getTypeForMode } from '../../data/quiz';
import { translateKwami } from '../../helpers/intl';
import { Mode, ModeType } from '../../types/mode';
import { Answer } from '../../types/quiz';
import CharacterImage from '../MiraculousImage/CharacterImage';
import KwamiImage from '../MiraculousImage/KwamiImage';

interface QuizResultProps {
    answer: Answer;
    mode: Mode;
}

function QuizResult({ answer, mode }: QuizResultProps) {
    const { locale } = useIntl();

    switch (getTypeForMode(mode)) {
        case ModeType.GUESS_IMAGE:
            return <>
                <div className="text-center">
                    <KwamiImage kwami={answer.proper} />
                </div>
                <hr />
                <Container>
                    <Row>
                        <Col xs={6} xl={3}>
                            <FormattedMessage id="answers.correct" defaultMessage="Correct:" />
                        </Col>
                        <Col xs={6} xl={3}>
                            <strong>{translateKwami(answer.proper, answer.mode, locale)}</strong>
                        </Col>
                        {!answer.result ?
                            <>
                                <Col xs={6} xl={3}>
                                    <FormattedMessage id="answers.given" defaultMessage="Given:" />
                                </Col>
                                <Col xs={6} xl={3}>
                                    {isString(answer.given) ?
                                        answer.given :
                                        translateKwami(answer.given, answer.mode, locale)}
                                </Col>
                            </> :
                            null}
                    </Row>
                </Container>
            </>;

        case ModeType.GUESS_IMAGE_BY_IMAGE:
            return <>
                <div className="text-center">
                    {mode === Mode.GUESS_BY_CHARACTER ?
                        <CharacterImage kwami={answer.proper} /> :
                        <KwamiImage kwami={answer.proper} />}
                </div>
                <hr />
                <Container>
                    <Row>
                        <Col xs={6} xl={3}>
                            <FormattedMessage id="answers.correct" defaultMessage="Correct:" />
                        </Col>
                        <Col xs={6} xl={3}>
                            {mode === Mode.GUESS_CHARACTER ?
                                <CharacterImage kwami={answer.proper} /> :
                                <KwamiImage kwami={answer.proper} />}
                        </Col>
                        {!answer.result ?
                            <>
                                <Col xs={6} xl={3}>
                                    <FormattedMessage id="answers.given" defaultMessage="Given:" />
                                </Col>
                                <Col xs={6} xl={3}>
                                    {isString(answer.given) ?
                                        answer.given :
                                        (mode === Mode.GUESS_CHARACTER ?
                                            <CharacterImage kwami={answer.given} /> :
                                            <KwamiImage kwami={answer.given} />
                                        )}
                                </Col>
                            </> :
                            null}
                    </Row>
                </Container>
            </>;

        case ModeType.GUESS_BY_IMAGE:
        default:
            return <>
                <hr />
                <Container>
                    <Row>
                        <Col xs={6} xl={3}>
                            <FormattedMessage id="answers.correct" defaultMessage="Correct:" />
                        </Col>
                        <Col xs={6} xl={3}><KwamiImage kwami={answer.proper} /></Col>
                        {!answer.result ?
                            <>
                                <Col xs={6} xl={3}>
                                    <FormattedMessage id="answers.given" defaultMessage="Given:" />
                                </Col>
                                <Col xs={6} xl={3}>
                                    {isString(answer.given) ?
                                        answer.given :
                                        <KwamiImage kwami={answer.given} />}
                                </Col>
                            </> :
                            null}
                    </Row>
                </Container>
            </>;
    }
}

export default QuizResult;
