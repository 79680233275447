import { shuffle } from 'lodash';
import { IMAGES_BASE_URL } from '../data/kwamis';
import { QUESTIONS } from '../data/quiz';
import { Question } from '../types/quiz';

function exclude<L extends { id: number }>(list: L[], excludedId: number) {
    return list.filter(({ id }) => id !== excludedId);
}

export function getQuestions(questionsNumber: number, excludedId: number | null = null): Question[] {
    let available = excludedId === null ? [...QUESTIONS] : exclude<Question>(QUESTIONS, excludedId);
    const selected = [];
    while (selected.length < questionsNumber) {
        const selectedIndex = Math.floor(Math.random() * available.length);
        if (available[selectedIndex].id !== excludedId) {
            selected.push(available[selectedIndex]);
            available = exclude<Question>(available, available[selectedIndex].id);
        }
    }
    return selected;
}

export function getOptions(optionsNumber: number, question: Question) {
    return shuffle([...getQuestions(optionsNumber, question.id), question]);
}

export function preLoadImages() {
    QUESTIONS.forEach(({ image }) => {
        const img = new Image();
        img.src = `${IMAGES_BASE_URL}${image}`;
    });
}
