import 'flag-icons/css/flag-icons.min.css';
import React from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Globe2 } from 'react-bootstrap-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { getFlagClassName, TRANSLATIONS } from '../../helpers/intl';
import './Header.css';

interface HeaderProps {
    resetMode?: () => void;
}

function Header({ resetMode }: HeaderProps) {
    const { formatMessage, locale } = useIntl();
    return <Navbar expand="lg" className="Header bg-body-tertiary">
        <Container>
            <Navbar.Brand>
                <img height={30} width={30} src="/logo192.png" className="d-inline-block align-top"
                    alt={formatMessage({ defaultMessage: 'Miraculous quiz', id: 'app.title' })} />{' '}
                <Link to={`/${locale}`} onClick={() => {
                    if (resetMode) {
                        resetMode();
                    }
                }}>
                    <FormattedMessage id="app.title" defaultMessage="Miraculous quiz" />
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link key="quiz" as={Link} to={`/${locale}`} onClick={() => {
                        if (resetMode) {
                            resetMode();
                        }
                    }}>
                        <FormattedMessage id="menu.play-quiz" defaultMessage="Play quiz" />
                    </Nav.Link>
                    <Nav.Link key="kwamis" as={Link} to={`/${locale}/kwamis`}>
                        <FormattedMessage id="menu.kwamis-list" defaultMessage="Kwamis list" />
                    </Nav.Link>
                </Nav>
                <Nav>
                    <NavDropdown title={<>
                        <Globe2 /> <FormattedMessage id="intl.change-language" defaultMessage="Change language" />
                    </>} id="collasible-nav-dropdown">
                        {Object.entries(TRANSLATIONS)
                            .map(([key, value]) => key !== locale ?
                                <NavDropdown.Item key={key} as={Link} to={`/${key}`}>
                                    <span className={getFlagClassName(key)}></span> {value.name}
                                </NavDropdown.Item> :
                                <NavDropdown.Item key={key} as="span" >
                                    <span className={getFlagClassName(key)}></span> <strong>{value.name}</strong>
                                </NavDropdown.Item>)}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Container>
    </Navbar>;
}

export default Header;
