export enum Mode {
    GUESS_BY_CHARACTER = 'guess-by-character',
    GUESS_CHARACTER = 'guess-character',
    GUESS_IMAGE_BY_MIRACULOUS = 'guess-image-by-miraculous',
    GUESS_IMAGE_BY_NAME = 'guess-image-by-name',
    GUESS_IMAGE_BY_POWER = 'guess-image-by-power',
    GUESS_IMAGE_BY_TRANSFORMATION = 'guess-image-by-transformation',
    GUESS_MIRACULOUS = 'guess-miraculous',
    GUESS_NAME = 'guess-name',
    GUESS_POWER = 'guess-power',
    GUESS_TRANSFORMATION = 'guess-transformation',
    TYPE_NAME = 'type-name',
}

export enum ModeType {
    GUESS_BY_IMAGE = 'guess-by-image',
    GUESS_IMAGE = 'guess-image',
    GUESS_IMAGE_BY_IMAGE = 'guess-image-by-image',
}

export enum Skill {
    READ = 'read',
    WRITE = 'write',
}

export const Modes: { [mode: string]: { defaultMessage: string; difficultyLevel: number; skills: Skill[] } } = {
    [Mode.GUESS_BY_CHARACTER]: { defaultMessage: 'guess kwami by character', difficultyLevel: 2, skills: [] },
    [Mode.GUESS_CHARACTER]: { defaultMessage: 'guess character by kwami', difficultyLevel: 2, skills: [] },
    [Mode.GUESS_IMAGE_BY_MIRACULOUS]: {
        defaultMessage: 'guess kwami by miraculous', difficultyLevel: 4, skills: [Skill.READ],
    },
    [Mode.GUESS_IMAGE_BY_NAME]: { defaultMessage: 'guess kwami by name', difficultyLevel: 3, skills: [Skill.READ] },
    [Mode.GUESS_IMAGE_BY_POWER]: { defaultMessage: 'guess kwami by power', difficultyLevel: 4, skills: [Skill.READ] },
    [Mode.GUESS_IMAGE_BY_TRANSFORMATION]: {
        defaultMessage: 'guess kwami by transformation', difficultyLevel: 1, skills: [Skill.READ],
    },
    [Mode.GUESS_MIRACULOUS]: { defaultMessage: 'guess miraculous of kwami', difficultyLevel: 4, skills: [Skill.READ] },
    [Mode.GUESS_NAME]: { defaultMessage: 'guess name of kwami', difficultyLevel: 3, skills: [Skill.READ] },
    [Mode.GUESS_POWER]: { defaultMessage: 'guess power of kwami', difficultyLevel: 4, skills: [Skill.READ] },
    [Mode.GUESS_TRANSFORMATION]: {
        defaultMessage: 'guess transformation of kwami', difficultyLevel: 2, skills: [Skill.READ],
    },
    [Mode.TYPE_NAME]: { defaultMessage: 'type name of kwami', difficultyLevel: 5, skills: [Skill.READ, Skill.WRITE] },
};
